import React, { useEffect, useRef, useState } from "react";
import SelectField from "../../SelectField/SelectField";
import InputField from "../../InputField/InputField";
import Strings from "../../../utils/Strings";
import Spinner from "../../Spinner/Spinner";
import {
  casteOption,
  countryOptions,
  currencyOptions,
  educationOption,
  employedInOption,
  expectedCasteOption,
  expectLanguagesOptions,
  languagesOptions,
  manglikOptions,
  maritalOption,
  occupationOption,
  religionOption,
  spiritualMasterOptions,
  spiritualStatusOptions,
  subcasteOption,
  wantNRIOption,
} from "../../../utils/data";
import {
  config,
  getValueFromLocalStorage,
  localStorageKey,
  updateValueInLocalStorage,
} from "../../../utils/helper";
import { authorizedPost } from "../../../Config/networkWithToken";
import configuration from "../../../Config/configuration";

const Expectation = ({
  currentStep,
  steps,
  setCurrentStep,
  userDetails,
  userRole,
  setUserDetails,
}) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const prevFormData = useRef(null);
  const [filteredSubcasteOptions, setFilteredSubcasteOptions] = useState([]);

  useEffect(() => {
    if (userRole === "admin") {
      console.log(userDetails);
      if (userDetails) {
        fetchExpectations(userDetails);
      }
    } else {
      const userData = JSON.parse(localStorage.getItem("userData"));
      fetchExpectations(userData);
    }
  }, [userDetails]);

  // useEffect(() => {
  //   fetchExpectations();
  //   getValueFromLocalStorage(localStorageKey.USERDATA, "expectation");
  // }, []);
  const [formData, setFormData] = useState({
    ageFrom: "",
    ageTo: "",
    heightFrom: "",
    heightTo: "",
    expetedReligion: "",
    expetedMotherTongue: [],
    expetedCaste: [],
    expetedSubcaste: [],
    expetedCountrysLiving: [],
    expetedEthnicities: [],
    expetedMaritalStatus: [],
    expetedWantNRI: [],
    expetedLanguagesCanSpeak: [],
    expetedLanguagesCanWrite: [],
    expetedEducation: [],
    expetedOccupations: [],
    expetedCountOfMantra: "",
    expetedSpiritualStatus: [],
    expetedEmployedIn: [],
    expetedIncomeFrom: "",
    expetedIncomeTo: "",
    expetedSpiritualMaster: [],
    expetedPartnerDescription: "",
    countryCurrency: [],
    expectedCity: "",
    expectedRegulativePrinciples: [],
    expectedManglick: "",
  });

  useEffect(() => {
    if (formData.expetedCaste && formData.expetedCaste.length > 0) {
      const subcasteOptions = formData.expetedCaste.flatMap(
        (caste) => subcasteOption[caste] || [],
      );
      setFilteredSubcasteOptions(subcasteOptions);
    } else {
      setFilteredSubcasteOptions([]);
    }
  }, [formData.expetedCaste]);

  const fetchExpectations = (data) => {
    // const userData = JSON.parse(localStorage.getItem("userData"));
    const userData = data;
    if (userData) {
      const fetchedData = {
        ageFrom: userData?.profile?.expectations?.ageFrom,
        ageTo: userData?.profile?.expectations?.ageTo,
        heightFrom: userData?.profile?.expectations?.heightFrom,
        heightTo: userData?.profile?.expectations?.heightTo,
        expetedReligion: userData?.profile?.expectations?.expetedReligion,
        expetedMotherTongue:
          userData?.profile?.expectations?.expetedMotherTongue,
        expetedCaste: userData?.profile?.expectations?.expetedCaste,
        expetedSubcaste: userData?.profile?.expectations?.expetedSubcaste,
        expetedCountrysLiving:
          userData?.profile?.expectations?.expetedCountrysLiving,
        expetedEthnicities: userData?.profile?.expectations?.expetedEthnicities,
        expetedMaritalStatus:
          userData?.profile?.expectations?.expetedMaritalStatus,
        expetedWantNRI: userData?.profile?.expectations?.expetedWantNRI,
        expetedLanguagesCanSpeak:
          userData?.profile?.expectations?.expetedLanguagesCanSpeak,
        expetedLanguagesCanWrite:
          userData?.profile?.expectations?.expetedLanguagesCanWrite,
        expetedEducation: userData?.profile?.expectations?.expetedEducation,
        expetedOccupations: userData?.profile?.expectations?.expetedOccupations,
        expetedCountOfMantra:
          userData?.profile?.expectations?.expetedCountOfMantra,
        expetedSpiritualStatus:
          userData?.profile?.expectations?.expetedSpiritualStatus,
        expetedEmployedIn: userData?.profile?.expectations?.expetedEmployedIn,
        expetedIncomeFrom: userData?.profile?.expectations?.expetedIncomeFrom,
        expetedIncomeTo: userData?.profile?.expectations?.expetedIncomeTo,
        expetedSpiritualMaster:
          userData?.profile?.expectations?.expetedSpiritualMaster,
        expetedPartnerDescription:
          userData?.profile?.expectations?.expetedPartnerDescription,
        countryCurrency: userData?.profile?.expectations?.countryCurrency?.[0],
        expectedCity: userData?.profile?.expectations?.expectedCity,
        expectedManglick: userData?.profile?.expectations?.expectedManglick,
        expectedRegulativePrinciples:
          userData?.profile?.expectations?.expectedRegulativePrinciples,
      };

      setFormData(fetchedData);
      prevFormData.current = fetchedData;
    }
  };

  const validate = (values) => {
    const errors = {};
    const requiredFields = [
      "ageFrom",
      "ageTo",
      "heightFrom",
      "heightTo",
      "expetedCaste",
      "expetedMotherTongue",
      "expetedEducation",
      "expetedOccupations",
      "expetedSpiritualMaster",
      "expetedSpiritualStatus",
      "expetedIncomeFrom",
      "expetedIncomeTo",
      "countryCurrency",
    ];
    requiredFields.forEach((field) => {
      if (
        !values[field] ||
        (Array.isArray(values[field]) && values[field].length === 0)
      ) {
        errors[field] = "Required";
      }
    });

    // "From" and "To" fields validation
    if (
      values.ageFrom &&
      values.ageTo &&
      parseInt(values.ageFrom) > parseInt(values.ageTo)
    ) {
      errors.ageTo = '"Age To" should be greater than or equal to "Age From"';
    }

    if (
      values.heightFrom &&
      values.heightTo &&
      parseFloat(values.heightFrom) > parseFloat(values.heightTo)
    ) {
      errors.heightTo =
        '"Height To" should be greater than or equal to "Height From"';
    }

    if (
      values.expetedIncomeFrom &&
      values.expetedIncomeTo &&
      parseInt(values.expetedIncomeFrom) > parseInt(values.expetedIncomeTo)
    ) {
      errors.expetedIncomeTo =
        '"Income To" should be greater than or equal to "Income From"';
    }

    return errors;
  };

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target ? e.target : e;

    let newValue;

    if (type === "number") {
      const numericValue = Number(value);
      newValue = numericValue < 0 ? 0 : numericValue;
    } else if (name === "expectedRegulativePrinciples") {
      newValue = checked
        ? [...(formData?.expectedRegulativePrinciples || []), value]
        : (formData?.expectedRegulativePrinciples || []).filter(
            (item) => item !== value,
          );
    } else {
      newValue = type === "checkbox" ? checked : value;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validate(formData);
    if (Object.keys(newErrors).length === 0) {
      setIsButtonLoading(true);

      if (JSON.stringify(prevFormData.current) === JSON.stringify(formData)) {
        handleNext("next");
        return;
      }
      const data = { expectations: { ...formData } };
      console.log(data);

      try {
        let res = [];
        if (userRole === "admin") {
          res = await authorizedPost(
            `${configuration.apis.saveProfile}?editUserId=${userDetails?._id}`,
            data,
            config,
          );
        } else {
          res = await authorizedPost(
            configuration.apis.saveProfile,
            data,
            config,
          );
        }
        if (res.data.status) {
          handleNext("next");
          const updatedProfileData = res?.data?.data;
          const profileCompletionPercentage =
            res?.data?.profileCompletionPercentage;
          console.log(updatedProfileData);

          if (userRole === "admin") {
            if (updatedProfileData) {
              setUserDetails({
                ...userDetails,
                profile: {
                  ...userDetails.profile,
                  expectations: { ...updatedProfileData.profile.expectations },
                },
              });
            }
          } else {
            if (updatedProfileData) {
              Object.keys(updatedProfileData).forEach((field) => {
                updateValueInLocalStorage(
                  "userData",
                  field,
                  updatedProfileData[field],
                );
              });
            }
            if (profileCompletionPercentage) {
              updateValueInLocalStorage(
                "userData",
                "profileCompletionPercentage",
                profileCompletionPercentage,
              );
            }
          }
        }
      } catch (err) {
        console.log("error", err);
      } finally {
        setIsButtonLoading(false);
      }
    } else {
      setErrors(newErrors);
    }
  };

  const handleNext = (direction) => {
    if (direction === "next" && currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
    } else if (direction === "prev" && currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const RequiredLabel = ({ label }) => (
    <span>
      {label}
      <span className="text-red-500">*</span>
    </span>
  );

  const fourRegulativePrinciplesOptions = [
    "No meat eating",
    "No gambling",
    "No intoxication",
    "No illicit sex",
  ];
  return (
    <div className="font-helvetica flex min-h-screen w-full items-center justify-center">
      <div className="mx-auto mb-14 mt-10 flex w-[95%] flex-col gap-1 rounded-lg bg-bgWhite pb-3 ">
        <form
          onSubmit={handleSubmit}
          className="mt-4 flex w-full flex-col gap-3 px-2 lg:gap-0 lg:p-1"
        >
          <div>
            <div className="mx-auto mt-5 flex w-[95%] flex-col gap-5 pb-3">
              <div className="flex flex-col gap-3 ">
                {/* <h3 className="font-bold text-textGreen">
                  {Strings.ADVANCE_BIO}
                </h3> */}
                <h1 className="pb-3 text-3xl">{"Spouse Expectation"}</h1>
                <hr />
              </div>
              <div className="pb-2">
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Age from" />}
                    name="ageFrom"
                    type="number"
                    placeholder="Enter Age from"
                    value={formData.ageFrom}
                    onChange={handleChange}
                    error={errors.ageFrom}
                  />
                  <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Age to" />}
                    name="ageTo"
                    type="number"
                    placeholder="Enter Age to"
                    value={formData.ageTo}
                    onChange={handleChange}
                    error={errors.ageTo}
                  />
                </div>
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Height from (in cms)" />}
                    name="heightFrom"
                    type="number"
                    placeholder="Enter Height from"
                    value={formData.heightFrom}
                    onChange={handleChange}
                    error={errors.heightFrom}
                  />
                  <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Height to (in cms)" />}
                    name="heightTo"
                    type="number"
                    placeholder="Enter Height to"
                    value={formData.heightTo}
                    onChange={handleChange}
                    error={errors.heightTo}
                  />
                </div>
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <SelectField
                    // isMulti={true}
                    selectType="field1"
                    className="w-full md:w-[50%]"
                    label="Religion"
                    name="expetedReligion"
                    placeholder="Select Religion"
                    options={religionOption}
                    value={formData.expetedReligion}
                    onChange={handleChange}
                  />

                  <SelectField
                    selectType="field1"
                    isMulti={true}
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Mother Tongue" />}
                    name="expetedMotherTongue"
                    placeholder="Select Mother tongue"
                    options={expectLanguagesOptions}
                    value={formData.expetedMotherTongue}
                    onChange={handleChange}
                    error={errors.expetedMotherTongue}
                  />
                </div>
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <SelectField
                    selectType="field1"
                    isMulti={true}
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Caste" />}
                    name="expetedCaste"
                    placeholder="Select Caste"
                    options={expectedCasteOption}
                    value={formData.expetedCaste}
                    onChange={handleChange}
                    error={errors.expetedCaste}
                  />
                  <SelectField
                    selectType="field1"
                    isMulti={true}
                    className="w-full md:w-[50%]"
                    label="Subcaste"
                    name="expetedSubcaste"
                    placeholder="Select Subcaste"
                    options={filteredSubcasteOptions}
                    value={formData.expetedSubcaste}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <SelectField
                    selectType="field1"
                    isMulti={true}
                    className="w-full md:w-[50%]"
                    label="Expectation Country"
                    name="expetedCountrysLiving"
                    placeholder="Expectation Country"
                    options={countryOptions}
                    value={formData.expetedCountrysLiving}
                    onChange={handleChange}
                  />
                  <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%]"
                    label={"Expectation City"}
                    name="expectedCity"
                    type="text"
                    placeholder="Enter Expectation City"
                    value={formData.expectedCity}
                    onChange={handleChange}
                    error={errors.expectedCity}
                  />
                </div>
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <SelectField
                    selectType="field1"
                    isMulti={true}
                    className="w-full md:w-[50%]"
                    label="Ethnicities "
                    name="expetedEthnicities"
                    placeholder="Select Ethnicities"
                    options={countryOptions}
                    value={formData.expetedEthnicities}
                    onChange={handleChange}
                  />
                  <SelectField
                    selectType="field1"
                    isMulti={true}
                    className="w-full md:w-[50%]"
                    label="Do You Want NRI "
                    name="expetedWantNRI"
                    placeholder="Select Do You Want NRI"
                    options={wantNRIOption}
                    value={formData.expetedWantNRI}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <SelectField
                    selectType="field1"
                    isMulti={true}
                    className="w-full md:w-[50%]"
                    label="Marital Status "
                    name="expetedMaritalStatus"
                    placeholder="Select Marital Status"
                    options={maritalOption}
                    value={formData.expetedMaritalStatus}
                    onChange={handleChange}
                  />
                  <SelectField
                    selectType="field1"
                    isMulti={true}
                    className="w-full md:w-[50%]"
                    label="Languages can Speak "
                    name="expetedLanguagesCanSpeak"
                    placeholder="Select Languages can Speak"
                    options={languagesOptions}
                    value={formData.expetedLanguagesCanSpeak}
                    onChange={handleChange}
                  />
                  {/* <SelectField
                    selectType="field1"
                    isMulti={true}
                    className="w-full md:w-[50%]"
                    label="Languages can write "
                    name="expetedLanguagesCanWrite"
                    placeholder="Select Languages can write"
                    options={languagesOptions}
                    value={formData.expetedLanguagesCanWrite}
                    onChange={handleChange}
                  /> */}
                </div>
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <div className="flex w-full flex-col flex-wrap gap-2 sm:flex-row md:w-[50%]">
                    <div className="flex items-center gap-2">
                      <label
                        className=" whitespace-nowrap text-xs font-medium md:text-sm"
                        htmlFor="fourRegulativePrinciples"
                      >
                        Regulative principles
                      </label>
                    </div>
                    <div className="flex flex-wrap items-center gap-3">
                      {fourRegulativePrinciplesOptions.map((option, index) => (
                        <div key={index} className="flex  items-center gap-3">
                          <input
                            className="h-[16px] w-[16px] cursor-pointer focus:outline-none"
                            type="checkbox"
                            value={option}
                            name="expectedRegulativePrinciples"
                            checked={formData?.expectedRegulativePrinciples?.includes(
                              option,
                            )}
                            onChange={handleChange}
                          />
                          <label className="whitespace-nowrap text-[12px] font-medium  md:text-sm">
                            {option}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <SelectField
                    selectType="field1"
                    isMulti={true}
                    className="w-full md:w-[50%]"
                    label="Manglik"
                    name="expectedManglick"
                    placeholder="Select Manglik"
                    options={manglikOptions}
                    value={formData.expectedManglick}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <hr className="border-2 border-textBlack" />
            </div>
            <div className="mx-auto flex w-[95%] flex-col gap-5 p-5">
              <div className="flex flex-col gap-3 ">
                <h3 className="font-bold text-textGreen">
                  {" "}
                  {/* {Strings.ADVANCE_BIO}{" "} */}
                </h3>
                <h1 className="pb-3 text-3xl">
                  {Strings.EDUCATION_EXPECTATION}
                </h1>
                <hr />
              </div>
              <div className="pb-5">
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <SelectField
                    selectType="field1"
                    isMulti={true}
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Education" />}
                    name="expetedEducation"
                    placeholder="Select Education"
                    options={educationOption}
                    value={formData.expetedEducation}
                    onChange={handleChange}
                    error={errors.expetedEducation}
                  />
                  <SelectField
                    selectType="field1"
                    isMulti={true}
                    className="w-full md:w-[50%]"
                    label="Nature Of Occupation"
                    name="expetedEmployedIn"
                    placeholder="Select Employed in"
                    options={employedInOption}
                    value={formData.expetedEmployedIn}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <SelectField
                    selectType="field1"
                    isMulti={true}
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Occupation Details" />}
                    name="expetedOccupations"
                    placeholder="Select Occupations"
                    options={occupationOption}
                    value={formData.expetedOccupations}
                    onChange={handleChange}
                    error={errors.expetedOccupations}
                  />
                  <SelectField
                    selectType="field1"
                    isMulti={true}
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Spiritual Masters" />}
                    name="expetedSpiritualMaster"
                    placeholder="Select Spiritual Masters"
                    options={spiritualMasterOptions}
                    value={formData.expetedSpiritualMaster}
                    onChange={handleChange}
                    error={errors.expetedSpiritualMaster}
                  />
                </div>
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%]"
                    label="No of Rounds Hare Krishna Maha Mantra"
                    name="expetedCountOfMantra"
                    type="number"
                    placeholder="No of Rounds Hare Krishna Maha Mantra"
                    value={formData.expetedCountOfMantra}
                    onChange={handleChange}
                  />

                  <SelectField
                    selectType="field1"
                    isMulti={true}
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Spritual Status" />}
                    name="expetedSpiritualStatus"
                    placeholder="Select SpritualStatus"
                    options={spiritualStatusOptions}
                    value={formData.expetedSpiritualStatus}
                    onChange={handleChange}
                    error={errors.expetedSpiritualStatus}
                  />
                </div>

                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Annual income from" />}
                    name="expetedIncomeFrom"
                    type="number"
                    placeholder="Enter Annual income from"
                    value={formData.expetedIncomeFrom}
                    onChange={handleChange}
                    error={errors.expetedIncomeFrom}
                  />
                  <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Annual income to" />}
                    name="expetedIncomeTo"
                    type="number"
                    placeholder="Enter Annual income to"
                    value={formData.expetedIncomeTo}
                    onChange={handleChange}
                    error={errors.expetedIncomeTo}
                  />
                </div>
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <SelectField
                    selectType="field1"
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Currency" />}
                    name="countryCurrency"
                    placeholder="Select Currency"
                    options={currencyOptions}
                    value={formData.countryCurrency}
                    onChange={handleChange}
                    error={errors.countryCurrency}
                  />
                  <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%]"
                    label="Partner description"
                    name="expetedPartnerDescription"
                    type="text"
                    placeholder="Enter describe yourself"
                    value={formData.expetedPartnerDescription}
                    onChange={handleChange}
                    error={errors.expetedPartnerDescription}
                  />
                </div>
              </div>
              <hr className="border-2 border-textBlack" />
            </div>
            {errors && (
              <div className="flex w-full items-center justify-center p-2">
                <p className="text-xs text-red-500">
                  {Object.entries(errors)
                    .map(([key, value]) => `${key} is ${value}`)
                    .join(", ")}
                </p>
              </div>
            )}
            <div className="container mb-2 flex justify-around">
              <button
                onClick={() => handleNext("prev")} // Wrap the function call in an arrow function
                type="button"
                className="cursor-pointer rounded-xl border bg-textYellow px-4 py-2  uppercase text-white "
              >
                Previous
              </button>
              <button
                type="submit"
                className="relative cursor-pointer rounded-xl border bg-textYellow px-4 py-2  uppercase text-white "
              >
                {isButtonLoading ? <Spinner /> : "Next"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Expectation;
