import React, { useEffect, useState } from "react";
import {
  FaBars,
  FaTimes,
  FaUsers,
  FaUserPlus,
  FaUserClock,
  FaUserSlash,
  FaUserCheck,
} from "react-icons/fa";
import {
  MdDashboard,
  MdAdminPanelSettings,
  MdLogout,
  MdQuiz,
  MdHistory,
} from "react-icons/md";
import { GiGloves, GiLovers, GiPostOffice } from "react-icons/gi";
import { FaPerson, FaRegNewspaper } from "react-icons/fa6";
import { Outlet, useNavigate } from "react-router-dom";
import Images from "../utils/images";
import Strings from "../utils/Strings";

const AdminSideBar = () => {
  const [selectedItem, setSelectedItem] = useState(0);
  const [showNav, setShowNav] = useState(false);
  const navigate = useNavigate();

  const handleClickItem = (index, path) => {
    setSelectedItem(index);
    navigate(path);
    if (path === "/") {
      console.log("logout");
      localStorage.clear();
      window.location.reload();
    }
  };

  const handleShowNav = () => {
    setShowNav(!showNav);
  };

  const list = [
    // {
    //   icon: <MdDashboard />,
    //   name: "Dash Board",
    //   path: "/dashboard",
    // },

    { icon: <GiLovers />, name: "Matrimony", path: "/matrimony" },
    { icon: <FaUserCheck />, name: "User Active", path: "/user-active" },
    { icon: <FaUserClock />, name: "User Request", path: "/user-request" },
    {
      icon: <MdQuiz />,
      name: "Questionnaire Request",
      path: "/questionnaire-request",
    },
    { icon: <GiGloves />, name: "Married Users", path: "/married-users" },
    { icon: <MdHistory />, name: "Suggestion History", path: "/suggestion-history" },

    {
      icon: <FaPerson />,
      name: "Men Attributes",
      path: "/men-attributes",
    },
    {
      icon: <FaRegNewspaper />,
      name: "News Letter",
      path: "/news-letter",
    },
    { icon: <GiPostOffice />, name: "Post Office", path: "/post-office" },
    { icon: <FaUserPlus />, name: "User Creation", path: "/register" },
    { icon: <FaUserSlash />, name: "Blocked Users", path: "/blocked-users" },
    { icon: <MdLogout />, name: "Log out", path: "/" },

    // { icon: <IoMdPhotos />, name: "Photo Lounge", path: "/photo-lounge" },
    // { icon: <CgWebsite />, name: "Sites", path: "/sites" },
    // { icon: <IoMdSettings />, name: "Flat Pages", path: "/flat-pages" },
    // {
    //   icon: <MdGroups />,
    //   name: "Authentication & Authorization",
    //   path: "/auth",
    // },
    // { icon: <FaUsersLine />, name: "Users", path: "/users" },
    // { icon: <GrTask />, name: "Periodic Tasks", path: "/periodic-tasks" },
    // {
    //   icon: <MdNotificationsActive />,
    //   name: "Notification",
    //   path: "/notification",
    // },
    // {
    //   icon: <TbFlagHeart />,
    //   name: "Django Flags",
    //   path: "/django-flags",
    // },
    // { icon: <IoMdSettings />, name: "Tos", path: "/tos" },
  ];
  useEffect(() => {
    const currentPath = window.location.pathname;
    const currentIndex = list.findIndex((item) =>
      currentPath.includes(item.path),
    );
    if (currentIndex !== -1) {
      setSelectedItem(currentIndex);
    }
  }, []);

  return (
    <div className="font-jost flex min-h-screen w-full bg-bgColor">
      <div className="fixed left-2 top-2 z-50 lg:hidden">
        <button
          onClick={handleShowNav}
          className="text-md inline-flex h-10 w-10 items-center justify-center rounded-[100%] bg-textYellowLight p-2 text-textGreen focus:outline-none focus:ring-2 focus:ring-textYellow"
        >
          {showNav ? <FaTimes /> : <FaBars />}
        </button>
      </div>

      {/* Sidebar */}
      <div
        className={`fixed left-0 z-40 h-full w-[255px] transform overflow-y-auto bg-bgWhite transition-transform duration-300 ease-in-out ${
          showNav ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0`}
      >
        <div
          className="mb-4 flex flex-col items-center justify-center"
          style={{ marginTop: "1rem" }}
        >
          <img
            src={Images.LOGO}
            alt="Marriage Logo"
            className="h-52 w-52"
            style={{ marginBottom: "-1rem" }}
          />
          {/* <h2 className="  text-black" style={{ marginTop: "-1rem" }}>
            {Strings.MARRIAGE_BUREAU}
          </h2> */}
        </div>

        {/* Navigation List */}
        <ul className=" mt-8 flex flex-col justify-start gap-3 text-lg">
          {list.map((item, index) => (
            <li
              key={index}
              className={`flex cursor-pointer items-center gap-3 p-1 
              ${
                selectedItem === index
                  ? "bg-textYellow text-bgWhite"
                  : "text-black hover:bg-bglightYellow"
              }`}
              onClick={() => handleClickItem(index, item.path)}
            >
              <span>{item.icon}</span>
              {item.name}
            </li>
          ))}
        </ul>
      </div>

      {/* Main Content */}
      <div className="flex-1 overflow-y-auto lg:ml-[255px]">
        <div
          className="w-full flex-col gap-4"
          style={{
            height: "calc(60vh - 1rem)",
            paddingTop: "5rem",
          }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminSideBar;
