import React, { useEffect, useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { FaChevronDown } from "react-icons/fa";
import { languages } from "countries-list";
import Strings from "../../../utils/Strings";
import { authorizedGet } from "../../../Config/networkWithToken";
import configuration from "../../../Config/configuration";
import { config } from "../../../utils/helper";
import Spinner from "../../../Components/Spinner/Spinner";
import { Country, State } from "country-state-city";

import {
  casteOption,
  educationOption,
  filterSubCasteOption,
  manglikOptions,
  maritalOption,
  occupationOption,
  religionOption,
  spiritualMasterOptions,
  spiritualStatusOptions,
} from "../../../utils/data";

const CustomDropdown = ({
  label,
  options = [],
  name,
  onChange,
  value = [],
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    const newValue = value.includes(option)
      ? value.filter((item) => item !== option)
      : [...value, option];
    onChange(name, newValue);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // console.log(options);

  let filteredOptions = [];
  if (options?.length > 0) {
     filteredOptions = options?.filter((option) =>
      option?.toLowerCase()?.includes(searchTerm?.toLowerCase()),
    );
  }
 

  const displayText =
    value.length === options.length
      ? "All"
      : value.length > 0
        ? value.join(", ")
        : label;

  return (
    <div>
      <button
        type="button"
        className="font-jost flex w-full items-center justify-between rounded-lg border p-2.5 text-xs text-textBlack"
        onClick={toggleDropdown}
      >
        {displayText}
        <FaChevronDown
          className={`transition-transform ${isOpen ? "rotate-180" : ""}`}
        />
      </button>
      {isOpen && (
        <div className="z-10 mt-1 w-full rounded-lg border bg-white shadow-lg">
          <input
            type="text"
            placeholder="Search..."
            className="font-jost w-full border-b p-2.5 text-xs text-textBlack"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          {value.length > 0 && (
            <button
              type="button"
              className="w-full p-2 text-red-500 hover:bg-gray-200"
              onClick={() => {
                onChange(name, []);
                setIsOpen(false);
              }}
            >
              Clear
            </button>
          )}

          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <div
                key={index}
                className={`cursor-pointer p-2 hover:bg-gray-200 ${
                  value.includes(option) ? "bg-gray-100" : ""
                }`}
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </div>
            ))
          ) : (
            <div className="p-2 text-gray-500">{Strings.NO_OPTION}</div>
          )}
        </div>
      )}
    </div>
  );
};

const languageOptions = Object.values(languages).map(
  (language) => language.name,
);
const motherTongueOptions = Object.values(languages).map(
  (motherTongue) => motherTongue.name,
);
const countryOptions = Country.getAllCountries().map((c) => c.name);


// const fetchFilteredData = async (queryParams) => {
//   try {
//     // const res = await authorizedGet(`${configuration.apis.userFilter}?status=active&${queryParams}`, config);
//     // const data = res?.data?.data || [];
//     // console.log("Fetched Data:", data);
//     return queryParams;
//   } catch (err) {
//     console.error("Error fetching data:", err);
//     return [];
//   }
// };

const sectionNameMap = {
  "By Status": "status",
  "By Caste": "caste",
  "By SubCaste": "subCaste",
  "By Spiritual Status": "spiritualStatus",
  "By Marital Status": "maritalStatus",
  "By Religion": "religion",
  "By Mother Tongue": "motherTongue",
  "By Country": "country",
  "By State": "state",
  "By Languages Can Speak": "languagesCanSpeak",
  "By Languages Can Write": "languagesCanWrite",
  "By Education": "education",
  "By Occupation": "occupations",
  "By Spiritual Master": "spiritualMaster",
  "By Manglik": "manglik",
};

const Filter = ({
  gender,
  handleShowFilter,
  onApplyFilters,
  userRole,
  userId,
}) => {
  const [openSection, setOpenSection] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({
    status: [],
    caste: [],
    subCaste: [],
    ageFrom: "",
    ageTo: "",
    incomeFrom: "",
    incomeTo: "",
    registerFrom: "",
    registerTo: "",
    heightFrom: "",
    heightTo: "",
    chantingFrom: "",
    chantingTo: "",
    spiritualStatus: [],
    religion: [],
    maritalStatus: [],
    motherTongue: [],
    country: [],
    state: [],
    languagesCanSpeak: [],
    languagesCanWrite: [],
    education: [],
    occupations: [],
    spiritualMaster: [],
    manglik: [],
  });
  useEffect(() => {}, [filteredData]);
  useEffect(() => {
    const countries = Country.getAllCountries();
    setCountryOptions(countries.map((country) => country.name));
  }, []);

  useEffect(() => {
    let allStates = [];
    if(filters?.country?.length > 0){
    filters?.country?.forEach((countryName) => {
      const selectedCountry = Country.getAllCountries().find(
        (c) => c.name === countryName
      );

      if (selectedCountry) {
        const countryIsoCode = selectedCountry.isoCode;
        const states = State.getStatesOfCountry(countryIsoCode);

        // Store states for each country
        allStates = [
          ...(allStates|| []), // Preserve existing states
          ...states.map((state) => state.name),
        ];
      
      }
    });

    // Set the state options for each selected country
    setStateOptions(allStates);
    console.log("State options for countries: ", allStates);
  }
  }, [filters.country]);

  const sections = [
    // {
    //   title: "By Status",
    //   data: ["Active", "Register"],
    // },
    {
      title: "By Caste",
      data: casteOption.map((option) => option.label),
    },
    {
      title: "By SubCaste",
      data: filterSubCasteOption.map((option) => option.label),
    },
    {
      title: "By Age",
      data: ["ageFrom", "ageTo"],
      type: "range",
    },
    {
      title: "By Annual Income INR",
      data: ["incomeFrom", "incomeTo"],
      type: "range",
    },
    {
      title: "By Registration Date",
      data: ["registerFrom", "registerTo"],
      type: "date",
    },
    {
      title: "By Round of Chanting",
      data: ["chantingFrom", "chantingTo"],
      type: "range",
    },
    {
      title: "By Height",
      data: ["heightFrom", "heightTo"],
      type: "range",
    },
    {
      title: "By Spiritual Status",
      data: spiritualStatusOptions.map((option) => option.label),
    },
    {
      title: "By Marital Status",
      data: maritalOption.map((option) => option.label),
    },
    {
      title: "By Religion",
      data: religionOption.map((option) => option.label),
    },
    {
      title: "By Mother Tongue",
      data: motherTongueOptions,
    },
    {
      title: "By Languages Can Speak",
      data: languageOptions,
    },

    // {
    //   title: "By Languages Can Write",
    //   data: languageOptions,
    // },
    {
      title: "By Country / State",
      type: "dropdown",
    },
    {
      title: "By Education",
      data: educationOption.map((option) => option.label),
    },
    {
      title: "By Occupation",
      data: occupationOption.map((option) => option.label),
    },

    {
      title: "By Spiritual Master",
      data: spiritualMasterOptions.map((option) => option.label),
    },
    {
      title: "By Manglik",
      data: manglikOptions.map((option) => option.label),
    },
  ];

  const genders = {
    male: "male",
    female: "female",
  };

  const role = {
    admin: "admin",
    user: "user",
  };

  const [errors, setErrors] = useState({});

  const handleChange = (name, value) => {
    if (Number(value) < 0) {
      return;
    }
    setFilters((prev) => {
      const updatedFilters = { ...prev, [name]: value };
      const baseName = name.replace(/From|To/, "");
      const fromValue = updatedFilters[`${baseName}From`];
      const toValue = updatedFilters[`${baseName}To`];
      if (fromValue && toValue && Number(fromValue) > Number(toValue)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [baseName]: `"From" value cannot exceed "To" value.`,
        }));
      } else {
        setErrors((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors[baseName];
          return updatedErrors;
        });
      }
      if (name === "registerFrom") {
        if (prev.registerTo && value > prev.registerTo) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: `Start date cannot be after end date!`,
          }));
        } else {
          setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[name];
            return updatedErrors;
          });
        }
      }

      if (name === "registerTo") {
        if (prev.registerFrom && value < prev.registerFrom) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: `End date cannot be before start date!`,
          }));
        } else {
          setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[name];
            return updatedErrors;
          });
        }
      }
      return updatedFilters;
    });
  };

  const buildQueryParams = (filters) => {
    const params = [];

    // append filters to the query string
    if (filters.status.length > 0) {
      params.push(`status=${filters.status.join(",")}`);
    }
    if (gender === "male") {
      params.push(`gender=${genders.female}`);
    }
    if (gender === "female") {
      params.push(`gender=${genders.male}`);
    }

    if (userRole === "admin") {
      params.push(`role=${role.admin}`);
    }

    if (userRole === "user") {
      params.push(`role=${role.user}`);
    }

    if (filters.ageFrom && filters.ageTo) {
      params.push(`ageFrom=${filters.ageFrom}&ageTo=${filters.ageTo}`);
    }
    if (filters.registerFrom && filters.registerTo) {
      params.push(
        `registerFrom=${filters.registerFrom}&registerTo=${filters.registerTo}`,
      );
    }
    if (filters.incomeFrom && filters.incomeTo) {
      params.push(
        `incomeFrom=${filters.incomeFrom}&incomeTo=${filters.incomeTo}`,
      );
    }
    if (filters.heightFrom && filters.heightTo) {
      params.push(
        `heightFrom=${filters.heightFrom}&heightTo=${filters.heightTo}`,
      );
    }
    if (filters.chantingFrom && filters.chantingTo) {
      params.push(
        `chantingFrom=${filters.chantingFrom}&chantingTo=${filters.chantingTo}`,
      );
    }
    if (filters.spiritualStatus.length > 0) {
      params.push(`spiritualStatus=${filters.spiritualStatus.join(",")}`);
    }
    if (filters.caste.length > 0) {
      params.push(`caste=${filters.caste.join(",")}`);
    }
    if (filters.subCaste.length > 0) {
      params.push(`subCaste=${filters.subCaste.join(",")}`);
    }
    if (filters.maritalStatus.length > 0) {
      params.push(`maritalStatus=${filters.maritalStatus.join(",")}`);
    }
    if (filters.religion.length > 0) {
      params.push(`religion=${filters.religion.join(",")}`);
    }
    if (filters.motherTongue.length > 0) {
      params.push(`motherTongue=${filters.motherTongue.join(",")}`);
    }
    if (filters.country.length > 0) {
      params.push(`country=${filters.country.join(",")}`);
    }
    if (filters.state.length > 0) {
      params.push(`state=${filters.state.join(",")}`);
    }
    if (filters.languagesCanSpeak.length > 0) {
      params.push(`languagesCanSpeak=${filters.languagesCanSpeak.join(",")}`);
    }
    // if (filters.languagesCanWrite.length > 0) {
    //   params.push(`languagesCanWrite=${filters.languagesCanWrite.join(",")}`);
    // }
    if (filters.education.length > 0) {
      params.push(`education=${filters.education.join(",")}`);
    }
    if (filters.occupations.length > 0) {
      // params.push(`occupations=${filters.occupations.join(",")}`);

      let updatedOccupatios = filters.occupations.map((value) =>
        value === "Agriculture & Farming Professional"
          ? "Agriculture %26 Farming Professional"
          : value,
      );

      params.push(`occupations=${updatedOccupatios.join(",")}`);
    }
    if (filters.spiritualMaster.length > 0) {
      params.push(`spiritualMaster=${filters.spiritualMaster.join(",")}`);
    }
    if (filters.manglik.length > 0) {
      let updatedManglik = filters.manglik.map((value) =>
        value === "I Don't Know" ? "N/A" : value,
      );

      params.push(`manglik=${updatedManglik.join(",")}`);
    }
    return params.length > 0 ? params.join("&") : "";
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (Object.keys(errors).length > 0) {
      setIsLoading(false);
      return;
    }

    const queryParams = buildQueryParams(filters);
    if (queryParams) {
      // const response = await fetchFilteredData(queryParams);
      onApplyFilters(queryParams);
    }

    setIsLoading(false);
  };

  const toggleSection = (title) => {
    setOpenSection((prev) => (prev === title ? null : title));
  };

  const handleClose = () => {
    handleShowFilter(false);
  };

  return (
    <div className="font-jost fixed inset-y-0 right-0 top-12 w-[300px] max-w-full overflow-y-auto bg-white">
      <div className="relative h-full p-6">
        <h3 className="p-2 text-center text-xl ">{Strings.FILTER_TEXT}</h3>
        <div className="absolute left-0 top-7 p-2">
          <IoIosCloseCircle
            className="cursor-pointer text-2xl"
            onClick={handleClose}
          />
        </div>
        <form onSubmit={handleSubmit}>
          {sections.map((section, index) => (
            <div key={index} className="relative mb-3">
              <h6 className="mb-0">
                <button
                  type="button"
                  onClick={() => toggleSection(section.title)}
                  className="group relative flex w-full cursor-pointer items-center border-b border-slate-100 p-4 text-left  text-slate-700 transition-all ease-in"
                >
                  <span>{section.title}</span>
                  <FaChevronDown
                    className={`absolute right-3 pt-1 text-base transition-transform ${
                      openSection === section.title ? "rotate-180" : ""
                    }`}
                  />
                </button>
              </h6>
              <div
                className={`${
                  openSection === section.title ? "block" : "hidden"
                } px-2 py-3`}
              >
                {/* Range Inputs */}
                {section.type === "range" &&
                  section.data.map((inputName) => {
                    const baseName = inputName.replace(/From|To/, "");
                    return (
                      <div key={inputName} className="mb-3">
                        <input
                          type="number"
                          name={inputName}
                          value={filters[inputName]}
                          onChange={(e) =>
                            handleChange(inputName, e.target.value)
                          }
                          placeholder={inputName}
                          className="font-jost w-full rounded-lg border p-2.5 text-xs text-textBlack"
                        />
                        {errors[baseName] && (
                          <p className="mt-1 text-xs text-red-500">
                            {errors[baseName]}
                          </p>
                        )}
                      </div>
                    );
                  })}

                {section.title === "By Country / State" && (
                  <>
                    <div className="mb-2">
                      <CustomDropdown
                        label="Country"
                        options={countryOptions}
                        name="country"
                        onChange={handleChange}
                        value={filters["country"]}
                      />
                    </div>
                    <div className="mb-2">
                      <CustomDropdown
                        label="State"
                        options={stateOptions}
                        name="state"
                        onChange={handleChange}
                        value={filters["state"]}
                        disabled={!filters["country"]}
                      />
                    </div>
                  </>
                )}
                {section.type === "date" &&
                  section.data.map((inputName) => (
                    <div key={inputName} className="mb-3">
                      <input
                        type="date"
                        name={inputName}
                        value={filters[inputName] || ""}
                        onChange={(e) =>
                          handleChange(inputName, e.target.value)
                        }
                        className={`font-jost w-full rounded-lg border p-2.5 text-xs text-textBlack ${
                          errors[inputName] ? "border-red-500" : ""
                        }`}
                      />
                      {errors[inputName] && (
                        <p className="mt-1 text-xs text-red-500">
                          {errors[inputName]}
                        </p>
                      )}
                    </div>
                  ))}

                {/* Dropdowns option */}
                {!section.type && (
                  <CustomDropdown
                    label={section.title}
                    options={section.data}
                    name={sectionNameMap[section.title]}
                    onChange={handleChange}
                    value={filters[sectionNameMap[section.title]]}
                  />
                )}
              </div>
            </div>
          ))}
          {/* button loader */}
          <div className="button-container pb-5">
            {Object.keys(errors).length > 0 && (
              <div className="mb-2 text-sm font-medium text-red-500">
                {<p>Please check the {Object.keys(errors)} field.</p>}
              </div>
            )}

            <button
              type="submit"
              className="apply-button mt-2 flex w-full items-center justify-center rounded-lg bg-textDarkGray p-2  text-sm text-bgWhite hover:bg-textPink"
              disabled={isLoading}
            >
              {isLoading ? <Spinner /> : Strings.APPLY}
            </button>
          </div>
        </form>
        {/* {filteredData.length > 0 && (
            <div className="mt-4">
            <h4 className="text-lg ">Filtered Results:</h4>
            <ul>
            {filteredData.map((item) => (
           <li key={item._id} className="mb-2 p-2 border-b">
          <p>{item.name}</p>
          <p>{item.age}</p>
          <p>{item.education}</p>
          
          </li>
          ))}
        </ul>
       </div>
      )} */}
      </div>
    </div>
  );
};

export default Filter;
