import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DOBASEURL } from "../../utils/helper";
import Images from "../../utils/images";

const SuggestionDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = location.state || {};

  return (
    <div className="">
      <div className="rounded-xl p-5">
        {/* Back Button */}
        <div className="flex items-center justify-between space-x-2">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center text-base text-gray-700 underline hover:text-gray-900 sm:text-lg"
          >
            <IoMdArrowRoundBack className="mr-2 text-base sm:text-lg" />
            <span className="font-medium">Back</span>
          </button>
        </div>

        {/* User Profile */}
        <div className="flex items-center justify-center">
          <div className="mx-auto flex max-w-[90%] flex-col items-center justify-center gap-x-6 rounded-lg border bg-white p-4 shadow-sm sm:max-w-[80%] sm:flex-row sm:items-start sm:p-6 lg:max-w-[600px]">
            <Link
              to={`/suggestion-history/suggestion-details/viewProfile/${user?._id || user?.userId}`}
            >
              <img
                src={
                  user?.photos?.length > 0
                    ? DOBASEURL + user?.photos?.[0]
                    : user?.gender === "male"
                      ? Images.MEN
                      : Images.WOMEN
                }
                alt={user?.name}
                className="aspect-square w-36 rounded-full border border-gray-300 object-cover"
              />
            </Link>

            <div className="flex w-full flex-col gap-y-2 text-center sm:text-left">
              <h2 className="text-sm font-semibold text-gray-800 sm:text-lg">
                {user?.name?.length
                  ? user?.name?.slice(0, 18) +
                    (user?.name.length > 15 ? "..." : "")
                  : "NA"}
              </h2>
              <div className="flex justify-center gap-x-2 text-xs text-gray-600 sm:justify-start sm:text-sm">
                <p>{user?.age} years</p>
                <p>|</p>
                <p>{user?.maritalStatus}</p>
              </div>
              <p className="text-xs text-gray-500 sm:text-sm">
                {user?.primaryEmail}
              </p>
              <p className="text-xs text-gray-500 sm:text-sm">{user?.phone}</p>

              <div className="my-3 w-full border-t border-gray-300"></div>

              <p className="text-center text-[10px] text-gray-600 sm:text-[12px]">
                Click the profile to view the full user details.
              </p>
            </div>
          </div>
        </div>

        {/* Matched Users Section */}
        <h3 className="mt-6 text-lg font-semibold text-gray-800">
          Matched Users
        </h3>
        <div className="mt-3 grid grid-cols-1 gap-3 xl:grid-cols-2">
          {user?.matchedUsers && user?.matchedUsers.length > 0 ? (
            user?.matchedUsers.map((match, index) => (
              <div
                key={index}
                className="rounded-lg border bg-white p-6 shadow-sm"
              >
                <div className="flex min-h-[100px] flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
                  {/* Profile Section */}
                  <div className="flex flex-col items-center sm:flex-row sm:items-center sm:gap-4">
                    <Link
                      to={`/suggestion-history/suggestion-details/viewProfile/${match?.matchedUserId}`}
                    >
                      <img
                        src={
                          match?.photos?.length > 0
                            ? DOBASEURL + match?.photos?.[0]
                            : match?.gender === "male"
                              ? Images.MEN
                              : Images.WOMEN
                        }
                        alt={match?.name}
                        className="h-20 w-20 rounded-full border border-gray-300 object-cover sm:h-24 sm:w-24"
                      />
                    </Link>

                    <div className="text-center sm:text-left">
                      <h4 className="text-md font-medium text-gray-800">
                        {match?.name}
                      </h4>
                      <p className="text-sm text-gray-600">
                        {match?.age} years | {match?.maritalStatus}
                      </p>
                      <p className="text-xs text-gray-500 sm:text-sm">
                        {match?.phone}
                      </p>

                      {/* Status */}
                      <p className="text-sm text-gray-600">
                        Status:{" "}
                        <span
                          className={`font-medium ${
                            match?.matchedUserStatus === "accepted"
                              ? "text-green-500"
                              : match?.matchedUserStatus === "pending"
                                ? "text-yellow-500"
                                : match?.matchedUserStatus === "rejected"
                                  ? "text-red-500"
                                  : "text-gray-600"
                          }`}
                        >
                          {match?.matchedUserStatus}
                        </span>
                      </p>

                      {/* Rejection Reason */}
                      <div className="max-w-[220px] text-sm text-gray-600">
                        <span className="font-medium">Rejected Reason:</span>
                        <div className="scrollbar-hide flex max-h-[50px] overflow-y-auto">
                          {match?.matchedUserComments?.trim() ? (
                            <span className="text-red-500">
                              {match?.matchedUserComments}
                            </span>
                          ) : (
                            <span className="text-gray-500">---</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Divider */}
                  <div className="hidden w-[1px] self-stretch bg-gray-300 sm:block"></div>

                  {/* User Status Section */}
                  <div className="flex flex-col items-center text-right sm:w-[200px] sm:items-end">
                    <p className="text-sm font-medium text-gray-800">
                      {user?.name?.length
                        ? user?.name?.slice(0, 15) +
                          (user?.name.length > 15 ? "..." : "")
                        : "NA"}
                    </p>

                    <p className="text-sm text-gray-600">
                      Status:
                      <br />
                      <span
                        className={`block font-medium ${
                          match?.userStatus === "accepted"
                            ? "text-green-500"
                            : match?.userStatus === "pending"
                              ? "text-yellow-500"
                              : match?.userStatus === "rejected"
                                ? "text-red-500"
                                : "text-gray-600"
                        }`}
                      >
                        {match?.userStatus}
                      </span>
                    </p>

                    {/* User Rejection Reason */}
                    <div className="max-w-[220px] text-sm text-gray-600">
                      <span className="font-medium">Rejected Reason:</span>
                      <div className="scrollbar-hide flex max-h-[50px] overflow-y-auto">
                        {match?.userComments?.trim() ? (
                          <span className="text-red-500">
                            {match?.userComments}
                          </span>
                        ) : (
                          <span className="text-gray-500">---</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Horizontal Divider */}
                <hr className="mt-4 w-full border-t border-gray-300" />

                {/* Footer Section */}
                <div className="flex flex-wrap items-center justify-center gap-x-2 text-sm text-gray-500">
                  <p>
                    <span className="font-medium">Suggested by:</span>{" "}
                    {match?.adminName}
                  </p>
                  <p>|</p>
                  <p>
                    <span className="font-medium">Suggested At:</span>{" "}
                    {new Date(match?.suggestionAt).toLocaleDateString(
                      "en-CA",
                    ) || "NA"}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <p className="text-sm text-gray-500">No matched users found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuggestionDetails;
