import React, { useEffect, useState } from "react";
import { FaCheckCircle, FaEye } from "react-icons/fa";
import Images from "../../utils/images";
import AdvanceSearch from "../AdvanceSearch/AdvanceSearch";
import { ImCancelCircle } from "react-icons/im";
import { IoIosSearch } from "react-icons/io";
import { authorizedGet } from "../../Config/networkWithToken";
import { authorizedPost } from "../../Config/networkWithToken";
import configuration from "../../Config/configuration";
import { Link } from "react-router-dom";

import MessageBar, {
  config,
  DOBASEURL,
  getValueFromLocalStorage,
  localStorageKey,
  successNotify,
} from "../../utils/helper";

const UserActive = () => {
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState("all");
  const [loading, setLoading] = useState(false);
  const adminId = getValueFromLocalStorage(localStorageKey.USERDATA, "adminId");
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [users, setUsers] = useState([]);

  const fetchActiveUser = async () => {
    setLoading(true);
    try {
      const res = await authorizedGet(
        `${configuration.apis.getAllUserVerifyRequests}?adminId=${adminId}`,
        config,
      );
      const responseStatus = res?.data;
      if (responseStatus?.status) {
        const response = responseStatus?.data;
        setUsers(response);
      }
    } catch (err) {
    }
    setLoading(false);
  };

  const userActive = async (userId, userVerifyId) => {
    setLoading(true);
    try {
      const res = await authorizedPost(
        configuration.apis.userVerifyApproval,
        {
          adminId,
          userId,
          userVerifyId,
        },
        config,
      );

      const responseStatus = res?.data;
      if (responseStatus?.status) {
        const response = responseStatus?.data;
        successNotify(res?.data?.message);
        setUsers(response);
      }
    } catch (err) {
      console.log("Error fetching data:", err);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchActiveUser();
  }, [adminId]);

  const handleAdvanceSearch = (searchValue) => {
    setSearchQuery(searchValue.toLowerCase());
  };

  const toggleSearchBar = () => {
    setShowAdvanceSearch((prev) => !prev);
    setSearchQuery("");
  };

  const filterUsersByRegisterDate = () => {
    const today = new Date();
    return users.filter((user) => {
      const registerDate = new Date(user?.createdAt);
      const diffTime = Math?.abs(today - registerDate);
      const diffDays = diffTime / (1000 * 60 * 60 * 24);

      if (filter === "today") return diffDays < 1;
      if (filter === "week") return diffDays < 7;
      if (filter === "month")
        return registerDate?.getMonth() === today?.getMonth();
      if (filter === "year")
        return registerDate?.getFullYear() === today?.getFullYear();

      return true;
    });
  };

  const filteredUsers = filterUsersByRegisterDate().filter((user) =>
    user?.name?.toLowerCase()?.includes(searchQuery),
  );


  return (
    <div className="p-4 sm:p-6">
      <div className="mb-4 flex flex-wrap items-center justify-between gap-3">
        <h2 className="text-lg font-semibold text-gray-800 sm:text-xl md:text-2xl">
          Activate Users{" "}
          <span className="text-[20px] font-normal text-gray-500">
            ({filteredUsers.length})
          </span>
        </h2>

        <div className="flex items-center gap-3">
          {/* Search Icon & Advanced Search */}
          <div className="flex items-center gap-2">
            {!showAdvanceSearch ? (
              <IoIosSearch
                className="cursor-pointer text-lg text-gray-500 sm:text-xl"
                onClick={toggleSearchBar}
              />
            ) : (
              <div className="flex items-center gap-2">
                <AdvanceSearch onSearchValue={handleAdvanceSearch} />
                <ImCancelCircle
                  className="cursor-pointer text-lg text-gray-500 sm:text-xl"
                  onClick={toggleSearchBar}
                />
              </div>
            )}
          </div>

          {/* Filter Dropdown */}
          <div className="flex items-center gap-2">
            <p className="text-sm text-gray-700 sm:text-base">Sort by:</p>
            <select
              className="rounded-md border px-2 py-1 text-sm sm:px-3 sm:py-2 sm:text-base"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="all">All</option>
              <option value="today">Today</option>
              <option value="week">This Week</option>
              <option value="month">This Month</option>
              <option value="year">This Year</option>
            </select>
          </div>
        </div>
      </div>

      <div className="mb-4 border-b border-gray-300"></div>

      {filteredUsers.length === 0 ? (
        <div className="text-center text-lg text-gray-500">No users found</div>
      ) : (
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
          {filteredUsers.map((user) => (
            <div
              key={user?.id}
              className="rounded-xl border bg-white p-4 shadow-sm transition-all duration-300 hover:shadow-md"
            >
              <div className="grid grid-cols-2 gap-4 sm:grid-cols-3">
                <div className="flex justify-center sm:justify-start">
                  <img
                    src={
                      user?.photos?.length > 0
                        ? DOBASEURL + user?.photos?.[0]
                        : user?.photos
                    }
                    alt={user?.name}
                    className="h-28 w-28 rounded-lg border border-gray-300 sm:h-32 sm:w-32"
                  />
                </div>
                <div className="flex flex-col justify-start text-left sm:col-span-2 sm:text-left">
                  <h3 className="text-base font-semibold sm:text-lg md:text-xl">
                    {user?.name?.length
                      ? user?.name?.slice(0, 18) +
                        (user?.name.length > 15 ? "..." : "")
                      : "NA"}
                  </h3>

                  <p className="text-sm text-gray-600 sm:text-base">
                    {user?.gender}
                  </p>
                  <p className="text-sm text-gray-600 sm:text-base">
                    {user?.maritalStatus}
                  </p>
                  <p className="text-sm text-gray-600 sm:text-base">
                    {user?.primaryEmail?.length
                      ? user?.primaryEmail?.slice(0, 20) +
                        (user?.primaryEmail.length > 20 ? "..." : "")
                      : "NA"}
                  </p>
                  <p className="text-xs text-gray-500 sm:text-sm">
                    Registered on:{" "}
                    <span className="font-normal">
                      {user.createdAt
                        ? new Date(user.createdAt).toLocaleDateString("en-GB")
                        : "N/A"}
                    </span>
                  </p>
                </div>
              </div>

              <div className="mt-4 flex flex-row justify-center gap-2 sm:gap-4">
                <button
                  onClick={() => userActive(user?.userId, user?.id)} // Pass both IDs
                  className="flex w-full items-center justify-center gap-2 rounded-md bg-green-500 px-3 py-2 text-xs text-white transition-colors duration-200 hover:bg-green-600 sm:w-auto sm:text-sm md:text-base"
                >
                  <FaCheckCircle size={16} /> Activate
                </button>

                <Link
                  to={`/user-active/viewProfile/${user?.userId}`}
                  className="w-full sm:w-auto"
                >
                 <button className="flex w-full items-center justify-center gap-2 rounded-md bg-textYellow px-3 py-2 text-xs text-white transition-colors duration-200 hover:bg-yellow-600 sm:w-auto sm:text-sm md:text-base">
                  <FaEye size={16} /> View Profile
                </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
      <MessageBar/>
    </div>
  );
};

export default UserActive;
