import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Images from "../../utils/images";
import { FaUserPen } from "react-icons/fa6";
import { DOBASEURL } from "../../utils/helper";

const ProfileActivation = () => {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const localStorageUserData = JSON.parse(localStorage.getItem("userData"));

    if (localStorageUserData) {
      setUserData(localStorageUserData);
      
    }
  }, [navigate]);

  if (!userData) {
    return (
      <div className="flex h-screen flex-col items-center justify-center text-lg text-gray-700">
        <div className="h-10 w-10 animate-spin rounded-full border-4 border-blue-500 border-t-transparent"></div>
        <p className="mt-4">Loading, please wait...</p>
      </div>
    );
  }

  const handleEditProfile = () => {
    navigate("/editprofile");
  };

  return (
    <div className="flex min-h-screen flex-col items-center bg-gray-100 p-6 pt-24">
      <div className="flex w-full max-w-4xl items-center justify-between rounded-t-md bg-textYellow p-4 text-lg font-semibold text-white">
        <span>Congrats, {userData?.name}!</span>
        <div onClick={handleEditProfile} className="cursor-pointer">
          <FaUserPen className="text-textBlack hover:text-bgWhite" size={20} />
        </div>
      </div>

      <div className="w-full max-w-4xl rounded-b-md border border-gray-200 bg-white p-6 text-center shadow-lg">
        <h2 className="text-xl font-bold ">
          You have successfully registered!
        </h2>
        <p className="mt-2 font-medium text-gray-700">
          Your Login Details on VaisnavaMatrimony:
        </p>

        <div className="mt-4 flex flex-col items-center">
        <img
            src={
              userData?.profile?.photos?.images.length > 0 ?
              DOBASEURL + userData?.profile?.photos?.images?.[0]
              :  Images.DEFAULT_USER
            }
            onContextMenu={(e) => e.preventDefault()}
            onDragStart={(e) => e.preventDefault()}
            className="ml-2 h-[150px] w-[150px] rounded-full object-cover"
            alt=""
          />
          <p className="pt-2 text-gray-700">{userData?.primaryEmail}</p>
          <p className="text-sm text-gray-600">
            {userData?.age} years | {userData?.maritalStatus}
          </p>
          <p className="text-gray-700">{userData?.phone}</p>
        </div>
        <p className="mt-3 text-sm text-gray-600">
        Your profile is awaiting approval. An admin will review it shortly. 
        </p>
        <div className="mt-4">
          <p>Stay logged in always at VaisnavaMatrimony</p>
        </div>

        <div className="mt-6 text-sm text-gray-600">
          Need help? Contact our 24/7 support:
          <a
            href="mailto:support@vaishnavamatrimony.com"
            className="text-blue-600 hover:underline"
          >
            {" "}
            vaisnavamarriage@gmail.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProfileActivation;
