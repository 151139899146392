import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Images from "../../utils/images";
import AdvanceSearch from "../AdvanceSearch/AdvanceSearch";
import { ImCancelCircle } from "react-icons/im";
import { IoIosSearch } from "react-icons/io";
import { authorizedGet } from "../../Config/networkWithToken";
import configuration from "../../Config/configuration";

import {
  config,
  DOBASEURL,
  getValueFromLocalStorage,
  localStorageKey,
} from "../../utils/helper";
import Spinner from "../Spinner/Spinner";

const SuggestionHistory = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
  const [filter, setFilter] = useState("all");
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const adminId = getValueFromLocalStorage(localStorageKey.USERDATA, "adminId");
  const fetchSuggestionHistory = async () => {
    setLoading(true);
    try {
      const res = await authorizedGet(
        `${configuration.apis.getSuggestionHistory}?adminId=${adminId}`,
        config,
      );
      const responseStatus = res?.data;
      if (responseStatus?.status) {
        const response = responseStatus?.data;
        setSuggestions(response);
      }
    } catch (err) {
      console.log("Error fetching data:", err);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchSuggestionHistory();
  }, [adminId]);

  const handleAdvanceSearch = (searchValue) => {
    setSearchQuery(searchValue.toLowerCase());
  };

  const toggleSearchBar = () => {
    setShowAdvanceSearch((prev) => !prev);
    setSearchQuery("");
  };
  const filterSuggestions = () => {
    const today = new Date();
    return suggestions.filter((user) => {
      const matchedUsers = user?.matchedUsers || [];

      // Get the  suggestionAt date
      const suggestionDates = matchedUsers
        .map((match) => new Date(match.suggestionAt))
        .filter((date) => !isNaN(date));

      if (suggestionDates.length === 0) return false;

      const suggestionDate = new Date(
        Math.max(...suggestionDates.map((d) => d.getTime())),
      );
      const diffTime = Math.abs(today - suggestionDate);
      const diffDays = diffTime / (1000 * 60 * 60 * 24);

      if (filter === "today") return diffDays < 1;
      if (filter === "week") return diffDays < 7;
      if (filter === "month")
        return suggestionDate.getMonth() === today.getMonth();
      if (filter === "year")
        return suggestionDate.getFullYear() === today.getFullYear();

      return true;
    });
  };

  const filteredSuggestions = filterSuggestions().filter((user) =>
    user.name.toLowerCase().includes(searchQuery),
  );

  return (
    <div className="p-4">
      <div className="mb-4 flex flex-wrap items-center justify-center gap-4">
        <h2 className="text-lg text-gray-800 sm:text-xl md:text-2xl lg:text-3xl">
          Suggestion History{" "}
          <span className="text-[20px] font-normal text-gray-500">
            ({filteredSuggestions.length})
          </span>
        </h2>
        {/* Search and Sort  */}
        <div className="ml-auto flex flex-wrap items-center gap-2 sm:gap-4">
          {/* Search Icon & Advanced Search */}
          <div className="flex items-center rounded-lg px-2 py-1">
            {!showAdvanceSearch ? (
              <IoIosSearch
                className="cursor-pointer text-xl text-gray-500 sm:text-2xl"
                onClick={toggleSearchBar}
              />
            ) : (
              <div className="flex items-center gap-2">
                <AdvanceSearch onSearchValue={handleAdvanceSearch} />
                <ImCancelCircle
                  className="cursor-pointer text-lg text-gray-500 sm:text-xl"
                  onClick={toggleSearchBar}
                />
              </div>
            )}
          </div>

          {/* sort Dropdown */}
          <p>sort by:</p>
          <select
            className="rounded-md border px-2 py-1 text-sm sm:text-base"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="all">All</option>
            <option value="today">Today</option>
            <option value="week">This Week</option>
            <option value="month">This Month</option>
            <option value="year">This Year</option>
          </select>
        </div>
      </div>

      <div className="mb-4 flex space-x-4 border-b border-gray-300"></div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {loading ? (
          <div className="col-span-3 flex min-h-screen items-center justify-center">
            <Spinner />
          </div>
        ) : filteredSuggestions.length > 0 ? (
          filteredSuggestions.map((user) => (
            <div
              key={user.id}
              className="flex cursor-pointer flex-col items-center rounded-xl bg-white p-6 text-center hover:border hover:border-textYellow"
              onClick={() =>
                navigate(
                  `/suggestion-history/suggestion-details/${user?._id || user?.userId}`,
                  {
                    state: { user },
                  },
                )
              }
            >
              <img
                src={
                  user?.photos?.length > 0
                    ? DOBASEURL + user?.photos?.[0]
                    : user?.gender === "male"
                      ? Images.MEN
                      : Images.WOMEN
                }
                alt={user?.name}
                className="h-24 w-24 rounded-full border border-gray-300 object-cover"
              />
              <h3 className="text-lg font-semibold text-gray-900">
                {user?.name?.length
                  ? user?.name?.slice(0, 18) +
                    (user?.name.length > 15 ? "..." : "")
                  : "NA"}
              </h3>
              <p className="text-sm text-gray-600">
                {user?.age} years | {user?.maritalStatus}
              </p>
              <p className="text-sm text-gray-500">{user?.gender}</p>
              <p className="mb-2 text-sm text-gray-500">{user?.phone}</p>
              <div className="my-3 w-full border-t border-gray-300"></div>
              <p className="text-[12px] text-gray-600">
                Click the profile to view the full suggestion details.
              </p>
            </div>
          ))
        ) : (
          <div className="col-span-3 flex w-full items-center justify-center">
            <p className="text-center text-xl text-gray-500">
              No results found.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SuggestionHistory;
