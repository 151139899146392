import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../../Spinner/Spinner";
import tick from "../../../Assets/tickImg.png";
import MessageBar, {
  config,
  errorNotify,
  getValueFromLocalStorage,
  localStorageKey,
  successNotify,
} from "../../../utils/helper";
import { authorizedPost } from "../../../Config/networkWithToken";
import configuration from "../../../Config/configuration";

const Complete = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const userId = getValueFromLocalStorage(localStorageKey.USERDATA, "_id");
  const userRole = getValueFromLocalStorage(localStorageKey.USERDATA, "role");
  const userData = JSON.parse(localStorage.getItem("userData"));

const handleOk = async () => {
  setLoading(true);

  if (userRole === "admin") {
    navigate(`/matrimony`);
    return;
  }

  const { profileCompletionPercentage, isActive } = userData || {};

  if (profileCompletionPercentage===100 && isActive) {
    navigate(`/profile/${userId}`);
  } if (profileCompletionPercentage===100 && !isActive) {
    await verifyUserStatus();
  }  

  setLoading(false);
};

const verifyUserStatus = async () => {
  try {
    const data = { userId };
    const res = await authorizedPost(configuration.apis.userVerify, data, config);

    if (res?.data?.status) {
      successNotify(res?.data?.message);
      setTimeout(() => {
        navigate(`/profile-activation`);
      }, 1000); 
      
    } else {
      errorNotify(res?.data?.message);
      setTimeout(() => {
        navigate(`/profile-activation`);
      }, 1000); 

    }
  } catch (error) {
    errorNotify(error?.response?.message || "An error occurred while processing the request.");
  }
};

  
  return (
    <div className="font-helvetica z-50 mt-6 flex min-h-screen w-full justify-center p-8">
      <div className="popup-content rounded-lg text-center">
        <img src={tick} className="mx-auto h-[100px] w-[100px]" alt="Tick" />
        <h2 className="mb-4 text-2xl ">Thank You!</h2>
        <p className="mb-8 text-xl">
          Your profile details have been completed successfully
        </p>
        <button
          type="button"
          className="ok-button rounded bg-textYellow px-4 py-2  text-white"
          onClick={handleOk}
          disabled={loading} // Disable button while loading
        >
          {loading ? <Spinner /> : "OK"}
        </button>
      </div>
      <MessageBar/>
    </div>
  );
};

export default Complete;
